<template>
  <div class="recycling-waste" style="background-color: #f8fcff;">
    <!-- <div class="banner">
    </div> -->
		<div class="banner">
      <el-carousel class="banner-carousel" indicator-position="outside">
        <el-carousel-item
          v-for="item in dashboardData.photos"
          :title="item.value3"
          :key="item.id"
        >
          <img
            :title="item.value3"
            :src="item.path"
            @click="linkToResolve(item.value4)"
            alt=""
          />
        </el-carousel-item>
      </el-carousel>
    </div>
    <el-card :body-style="{padding:'0' } " style="width:70.5%;margin: 20px auto;" class="box-card">
			<div class="car-header">
				<p class="p1">废旧物资回收</p>
				<p class="p2"></p>
				<p class="p3">
					公司长期回收废钢、有色金属、铜铝、塑料、橡胶、不锈钢等资源。开拓城市矿产，力主物资循环，我们在行动！
				</p>
				<div class="waste-hot">
					<span class="phone">咨询热线：</span>
					<span class="phone mr6">029-84979865</span>
					<span class="phone mr6">13709207664</span>
					<span class="phone">15029092571</span>
				</div>
    	</div>
      <div style="float: left;">
        <el-row class="tac">
          <el-col :span="20">
            <!-- <div style="text-align: center;margin-bottom: 20px;">
              <p
                style="background: linear-gradient(0deg, #181A7D, #30C9CD, #5BE196);width: 240px;height: 72px;font-size: 24px;color: white;line-height: 72px;">
                废旧物资回收</p>
            </div> -->
            <el-menu style="width: 240px;" :default-active="active" class="el-menu-vertical-demo" @select="handClick"
             background-color="#fdfeff" text-color="#333333"
              active-text-color="#24a2aa">
              <el-menu-item index="1">
                <span slot="title">废钢类</span>
              </el-menu-item>
              <el-menu-item index="2">
                <span slot="title">塑料类</span>
              </el-menu-item>
              <el-menu-item index="3">
                <span slot="title">橡胶类</span>
              </el-menu-item>
              <el-menu-item index="4">
                <span slot="title">有色金属类</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="lastBox-right">
        <el-row :gutter="20" class="recycling-waste-list" v-if="value === '1'">
					<el-col :span="8" class="recycling-waste-item" v-for="(item, index) in steelScrapImageList" :key="index">
						<img :src="item.src" alt=""/>
						<span class="recycling-waste-text">{{item.text}}</span>
					</el-col>
				</el-row>
        <el-row :gutter="20" class="recycling-waste-list" v-if="value === '2'">
					<el-col :span="12" class="recycling-waste-item" v-for="(item, index) in plasticsImageList" :key="index">
						<img :src="item.src" alt=""/>
						<span class="recycling-waste-text">{{item.text}}</span>
					</el-col>
				</el-row>
        <el-row :gutter="20" class="recycling-waste-list" v-if="value === '3'">
					<el-col :span="8" class="recycling-waste-item" v-for="(item, index) in rubberImageList" :key="index">
						<img :src="item.src" alt=""/>
						<span class="recycling-waste-text">{{item.text}}</span>
					</el-col>
				</el-row>
        <el-row :gutter="20" class="recycling-waste-list" v-if="value === '4'">
					<el-col :span="6" class="recycling-waste-item" v-for="(item, index) in ferrousMetalsImageList" :key="index">
						<img :src="item.src" alt=""/>
						<span class="recycling-waste-text">{{item.text}}</span>
					</el-col :span="8" >
				</el-row>
			</div>
    </el-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "adressMaintain",
  data() {
    return {
			value: '1',
			leftTitle: {
				1: '废钢类',
				2: '塑料类',
				3: '橡胶类',
				4: '有色金属类',
			},
			steelScrapImageList: [
				{
					src: '/img/car/steel_scrap1.png',
					text: '不锈钢'
				},
				{
					src: '/img/car/steel_scrap2.png',
					text: '车厢料'
				},
				{
					src: '/img/car/steel_scrap3.png',
					text: '大梁料'
				},
				{
					src: '/img/car/steel_scrap4.png',
					text: '钢板'
				},
				{
					src: '/img/car/steel_scrap5.png',
					text: '减震'
				},
				{
					src: '/img/car/steel_scrap6.png',
					text: '破碎料'
				},
				{
					src: '/img/car/steel_scrap7.png',
					text: '铁油箱'
				},
				{
					src: '/img/car/steel_scrap8.png',
					text: '发动机'
				},
				{
					src: '/img/car/steel_scrap9.png',
					text: '前后桥'
				},
			],
			plasticsImageList: [
				{
					src: '/img/car/plastics1.png',
					text: '内饰塑料'
				},
				{
					src: '/img/car/plastics2.png',
					text: '汽车大灯'
				},
				{
					src: '/img/car/plastics3.png',
					text: '塑料保险杠'
				},
				{
					src: '/img/car/plastics4.png',
					text: '塑料油箱'
				},
			],
			rubberImageList: [
				{
					src: '/img/car/rubber1.png',
					text: '大轮胎'
				},
				{
					src: '/img/car/rubber2.png',
					text: '空轮胎'
				},
				{
					src: '/img/car/rubber3.png',
					text: '实心胎'
				},
				{
					src: '/img/car/rubber4.png',
					text: '小轮胎'
				},
				{
					src: '/img/car/rubber5.png',
					text: '橡胶条'
				},
				{
					src: '/img/car/rubber6.png',
					text: '摩托车轮胎'
				},
			],
			ferrousMetalsImageList: [
				{
					src: '/img/car/ferrous1.png',
					text: 'ABS泵'
				},
				{
					src: '/img/car/ferrous2.png',
					text: '插头'
				},
				{
					src: '/img/car/ferrous3.png',
					text: '铝水箱'
				},
				{
					src: '/img/car/ferrous4.png',
					text: '铝条'
				},
				{
					src: '/img/car/ferrous5.png',
					text: '马达电机'
				},
				{
					src: '/img/car/ferrous6.png',
					text: '破碎铝'
				},
				{
					src: '/img/car/ferrous7.png',
					text: '散热器'
				},
				{
					src: '/img/car/ferrous8.png',
					text: '生铝'
				},
				{
					src: '/img/car/ferrous9.png',
					text: '铜水箱'
				},
				{
					src: '/img/car/ferrous10.png',
					text: '铜线'
				},
				{
					src: '/img/car/ferrous11.png',
					text: '铜转子'
				},
			]
    };
  },
  computed: {
    ...mapState({
      dashboardData: (state) => {
        return state.home.dashboardData;
      },
      loading: (state) => {
        return state.loading;
      },
    }),
  },
  created() {
    this.value = this.$route.query.value;
    this.active = this.$route.query.value;
    if (this.value == undefined) {
      this.value = '1';
    }
    if (this.active == undefined) {
      this.active = "1";
    }
    this.url = process.env.VUE_APP_API_BASE_URL;
  },
  methods: {
		handClick(index) {
      this.value = `${index}`;
    },
  },
};
</script>

<style lang="less" scoped>
.banner {
  text-align: center;
  height: 480px;
  // background-image: url(/img/lianxibg.png);
  // background-size: 100%;
  // line-height: 480px;
	
  .banner-carousel {
    width: 100%;
    height: 480px; /* no */
    position: relative;
    /deep/.el-carousel__container {
      height: 100%;
    }
    img {
      width: 100%;
      height: 100%;
    }
    /deep/.el-carousel__indicators--outside {
      position: absolute;
      left: 0;
      width: 100%;
      .el-carousel__button {
        height: 5px; /* no */
      }
    }
  }
}
.car-header {
  height: 180px;
  background-image: url(/img/pic.png);
	background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 30px 0 0 100px;

  .p1 {
    font-size: 36px;
    font-family: YouSheBiaoTi;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    // text-shadow: 0px 2px 11px rgba(4, 23, 117, 0.88);
    background: linear-gradient(0deg, #a2ffce 0%, #ecffff 50%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    // font-style: oblique;
    margin-bottom: 16px;
  }

  .p2 {
    width: 36px;
    height: 4px;
    background: linear-gradient(90deg, #26faff 0%, #2cff99 100%);
    margin-bottom: 12px;
  }

  .p3, .waste-hot {
    width: 726px;
    font-size: 18px;
    font-family: SourceHanSansCN-Light;
    font-weight: 300;
    color: #ffffff;
    line-height: 26px;
  }
	.waste-hot{
		padding-top: 8px;
	}
	.phone{
		text-indent: 4em;
		font-weight: bold;
		font-size: 24px;
		color: rgb(255, 102, 0);
	}
	.mr6{
		margin-right: 12px;
	}
}
.lastBox-right {
	padding: 2%;
  overflow: hidden;
}
.enterprise {
  margin: 0 auto;
  .p1 {
    span {
      font-size: 14px;
      margin-right: 29px;
    }
  }
}
.recycling-waste-list{
	.recycling-waste-item{
		text-align: center;
		font-size: 20px;
		>img {
			width: 100%;
			height: 300px;
		}
		>span{
			display: inline-block;
			line-height: 50px;
		}
	}
}
.waste-tip{
	text-align: center;
	max-width: 800px;
	margin: 0 auto;
	font-size: 24px;
	padding-top: 20px;
	padding-bottom: 10px;
	line-height: 30px;
	.waste-hot{
		padding-top: 6px;
	}
	.phone{
		text-indent: 4em;
		font-weight: bold;
		font-size: 30px;
		color: rgb(255, 102, 0);
	}
}
/deep/.el-menu-item.is-active {
  background-color: #24a2aa !important;
  color: white !important;
}
/deep/.el-timeline-item__timestamp.is-top {
  position: absolute;
  left: -117px;
  top: -3px;
  color: #333333;
}
/deep/.el-timeline {
  padding-left: 150px;
}
.vjs-custom-skin {
  width: 100%;
  height: 100%;
}
</style>
